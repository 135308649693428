export const menuItems = [
  { text: 'Dashboard', routeName: 'dashboard', icon: 'fas fa-tachometer-alt'},
  { text: 'Create Gig', routeName: 'gig.create', icon: 'fas fa-plus' },
  { text: 'Gigs hub', routeName: 'user.gigs', icon: 'fas fa-briefcase' },
  { text: 'Public Gigs', routeName: 'gigsearch', icon: 'fas fa-briefcase' },
  { text: 'Profile hub', routeName: 'connections', icon: 'fas fa-users' },
  { text: 'Messages', routeName: 'messaging', icon: 'fas fa-envelope' },
  { text: 'Calendar', routeName: 'calendar', icon: 'fas fa-calendar-alt' },
  { text: 'Edit Profile', href: '/user/profile', icon: 'fas fa-edit' }
];
