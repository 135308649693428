<script setup>
  import { ref, computed } from 'vue';
  import { usePage } from '@inertiajs/vue3';
  import LogoSVGWhite from '@/Components/LogoSVGWhite.vue';
  import { Link } from '@inertiajs/vue3';
  import { menuItems } from '@/constants/menuItems';

  const { props } = usePage();
  const isLoggedIn = computed(() => !!props.auth.user);

  const navLinksForVisitors = [
    { text: 'About Us', href: '/about' },
    { text: 'Profiles', href: '/visitor-profiles-page' },
    { text: 'Gigs', href: '/visitor-gigs-page' },
    { text: 'Messaging', href: '/waitlist?p=messaging' },
    { text: 'Registration', href: '/waitlist' },
    { text: 'Login', href: '/login' }
  ];

  const currentYear = new Date().getFullYear();
</script>

<template>
  <div class="bg-black text-white">
    <div class="mx-auto flex max-w-4xl flex-col p-2 pt-10 md:flex-row md:pt-20">
      <div class="flex w-full flex-col md:w-2/5">
        <Link :href="'/'" class="flex md:-ml-16">
          <LogoSVGWhite class="" />
        </Link>
        <div
          class="mx-auto flex w-2/3 flex-wrap justify-start text-center text-xs md:w-full md:pr-12 md:text-left"
        >
          <p>
            Spicygigs is committed to providing creators with the tools to
            thrive. Have questions? Reach out to our support team.
          </p>
        </div>
        <div class="">
          <p class="mt-4 text-xs font-semibold">Follow Us On</p>
          <div class="mt-2 flex flex-row gap-3">
            <div
              class="flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-purple-500 transition-colors duration-300 hover:bg-purple-700"
              v-show="false"
            >
              <i class="fa-brands fa-facebook text-white"></i>
            </div>
            <div
              class="flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-purple-500 transition-colors duration-300 hover:bg-purple-700"
            >
              <a href="https://www.instagram.com/myspicygigs/" target="_blank">
                <i class="fa-brands fa-square-instagram text-white"></i>
              </a>
            </div>
            <div
              class="flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-purple-500"
              v-show="false"
            >
              <i class="fa-brands fa-linkedin text-white"></i>
            </div>
            <div
              class="flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-purple-500 transition-colors duration-300 hover:bg-purple-700"
            >
              <a href="https://x.com/myspicygigs" target="_blank">
                <i class="fa-brands fa-x-twitter text-white"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row text-sm md:w-3/5">
        <div class="flex w-1/4 flex-col">
          <p class="mb-1 mt-2 font-semibold md:mb-3 md:mt-0">Visitors</p>
          <ul>
            <li v-for="(link, index) in navLinksForVisitors" :key="index">
              <a
                :href="link.href"
                class="!text-white hover:!text-white hover:underline"
              >
                {{ link.text }}
              </a>
            </li>
          </ul>
        </div>

        <div class="mr-4 flex w-1/4 flex-col">
          <p class="mb-1 mt-2 font-semibold md:mb-3 md:mt-0">Creators/Models</p>
          <ul>
            <li v-for="(item, index) in menuItems" :key="index">
              <template v-if="isLoggedIn && !item.disabled && item.href">
                <Link
                  :href="item.href"
                  class="block !text-white hover:!text-white hover:underline"
                >
                  {{ item.text }}
                </Link>
              </template>
              <template v-else>
                <span
                  class="block cursor-not-allowed !text-white hover:!text-gray-500"
                  :aria-disabled="true"
                >
                  {{ item.text }}
                </span>
              </template>
            </li>
          </ul>
        </div>

        <div class="flex w-1/4 flex-col">
          <p class="mb-1 mt-2 font-semibold md:mb-3 md:mt-0">Company</p>
          <ul>
            <li class="">
              <Link
                class="!text-white hover:!text-white hover:underline"
                href="/welcome-page"
              >
                Welcome
              </Link>
            </li>
            <li class="">
              <Link
                class="!text-white hover:!text-white hover:underline"
                href="/about"
              >
                About
              </Link>
            </li>
            <!-- <li class="homepage-text64 TypographyBodyText">Partners</li> -->
            <li class="">
              <a
                href="mailto:contact@spicygigs.com"
                class="!text-white hover:!text-white hover:underline"
              >
                Contact
              </a>
            </li>
            <!-- <li class="homepage-text66 TypographyBodyText">Calendar</li> -->
            <!-- <li class="homepage-text67 TypographyBodyText">Testimonials</li> -->
          </ul>
        </div>
        <div class="flex w-1/4 flex-col">
          <p class="mb-1 mt-2 font-semibold md:mb-3 md:mt-0">Legal</p>
          <ul>
            <li class="">
              <Link
                href="/docs/terms-of-service"
                class="!text-white hover:!text-white hover:underline"
              >
                Terms Of Service
              </Link>
            </li>
            <li>
              <Link
                href="/docs/privacy-policy"
                class="!text-white hover:!text-white hover:underline"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="mx-auto mt-10 w-5/6" />
    <p class="my-4 text-center text-sm">
      © {{ currentYear }} Copyright Spicygigs, All Rights Reserved
    </p>
  </div>
</template>

<style scoped></style>
